.sidebar {
  padding: 15px;
  width: 100%;
  transition: all 450ms;
  text-align: center;

  @include breakpoint(sm) {
    height: 550px;
  }
  @include breakpoint(md) {
    width: 400px;
    height: 100%;
    position: fixed;
    background-color: #fafcfe;
  }
  @include breakpoint(lg) {
    width: 464px;
  }
  .logo {
    margin-top: 4px;
  }
  .site-info {
    padding: 60px 15px 0 15px;

    @include breakpoint(sm) {
      padding: 80px 100px 0 100px;
    }
    @include breakpoint(md) {
      padding: 0 20px;
      position: absolute;
      bottom: 40px;
    }
    @include breakpoint(lg) {
      padding: 0 30px;
    }
    h1 {
      letter-spacing: -2pt;
      margin-bottom: 0;
      @include breakpoint(sm) {
        letter-spacing: 0pt;
        margin-bottom: 10px;
        @include font-size(34);
      }
    }
    p {
      margin-bottom: 10px;
      color: #757575;
      @include line-height(24);
      @include font-size(16);
    }
    i {
      font-style: normal;
      margin-right: 10px;
    }
    .primary-info {
      border-bottom: solid 1px rgba(255,255,255,0.3);

      a {
        color: #E0E0E0;
      }
      a:hover,
      a:active {
        color: #fafafa;
      }
    }
    .secondary-info {
      p {
        margin: 20px 0 0;
        @include font-size(14);
      }
    }
  }
}