.main-content {
  padding: 30px;

  @include breakpoint(sm) {
    padding: 60px 120px;
  }
  @include breakpoint(md) {
    padding: 50px;
  }
}
.sub-nav {
  border-bottom: solid 1px #f5f5f5;
  line-height: 30px;

  a {
    display: inline-block;
    margin-right: 10px;
    line-height: 30px;
    letter-spacing: 2pt;
    text-decoration: none;
    color: $light-grey;
    @include up-bold;
    @include font-size(12);

    &:hover,
    &:active,
    &.active {
      border-bottom: solid 2px #000;
      color: $text-color;
    }
  }
}
.post {
  padding: 30px 0;
  border-bottom: solid 1px #f5f5f5;
  @include clearfix;

  .post-preview {
    h2 {
      margin-top: 0;
      font-weight: 700;
      @include font-size(24);

      @include breakpoint(md) {
        @include font-size(32);
      }
      a {
        text-decoration: none;
        color: #333337;
        border: none;

        &:hover {
          color: $light-grey;
        }
      }
    }
    .excerpt {
      @include font-size(16);
      color: rgba(117, 117, 117, 1);

      @include breakpoint(md) {
        @include font-size(18);
      }
    }
  }
  &.author-page {
    .post-preview p {
      margin: 0;
    }
  }
}
.category {
  margin-top: 15px;
  margin-bottom: 15px;
}
.category-preview {
  margin: 15px 0;

  h2 {
    margin: 0;
    padding: 10px;
    position: absolute;
    bottom: 0;
    @include font-size(20);
    color: #fff;
  }
}
.split-footer {
  padding: 10px 0;
  @include font-size(11);
  letter-spacing: 1px;
  @include up-bold;

  a {
    color: #999;
    border-bottom: none;

    &:hover,
    &:active {
      color: #333;
      border-bottom: none;
    }
  }
}