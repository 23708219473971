.no-js-menu {
  background-color: #333337;
  position: fixed;
  width: 100%;
  z-index: 100;

  ul {
    padding: 0;
    margin-bottom: 0;
  }
  li {
    margin: 0;
    padding: 3px 0;
    list-style: none;
    float: left;

    @include breakpoint(sm) {
      padding: 5px 0;
    }
    @include breakpoint(md) {
      padding: 10px 0;
    }
    i {
      padding: 0 7px 0 30px;
      color: #DADADA;
      font-style: normal;
      @include font-size(14);
    }
    a {
      color: #DADADA;
      font-weight: 700;
      border-bottom: 0 transparent;
      @include font-size(12);

      &:hover {
        border-bottom: none;
        color: #fff;
      }
    }
  }
}
.no-js {

  .sidebar {
    height: 400px;

    @include breakpoint(sm) {
      height: 450px;
    }
    @include breakpoint(md) {
      width: 400px;
      height: 100%;
      position: fixed;
      background-color: #fafcfe;
    }
    @include breakpoint(lg) {
      width: 464px;
    }
    .menu-trigger {
      display: none;
    }
  }
  .site-info {
    padding: 0 15px 0 15px;

    @include breakpoint(sm) {
      padding: 0 100px 0 100px;
    }
    @include breakpoint(md) {
      padding: 0 20px;
      position: absolute;
      bottom: 40px;
    }
    @include breakpoint(lg) {
      padding: 0 30px;
    }
  }
  header {
    .menu-trigger {
      display: none;
    }
  }
}
.no-js-dashboard {
  margin-top: 60px;
}