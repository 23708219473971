.home-post {
  &__meta {
    margin-bottom: 10px;

    img {
      box-sizing: border-box;
      max-width: 30px;
      max-height: 30px;
      border-radius: 50%;
      margin-right: 5px;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: -moz-optimize-contrast;
      image-rendering: optimize-contrast;
    }

    span {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
}

.excerpt {
  p {
    margin-bottom: 8px;
  }
}

.post {
  .post-preview .post-footer {
    font-size: 1.5rem;
  }
}

.gist-meta {
  display: none;
}

.gist .gist-data {
  border-bottom: none !important;
}

.gist .gist-file {
  border-radius: 0 !important;
}
