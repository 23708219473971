form {
  @include clearfix;
  label {
    @include font-size(20);
    margin: 15px 0 0;
  }
  input.form-control,
  textarea.form-control {
    border: solid 0px transparent;
    box-shadow: none;
    border-bottom: solid 3px;
    border-radius: 0;
    padding: 5px 0;
    background-color: #fff;
    resize: vertical;
    @include placeholder {
      color: #ccc;
    }
    &:focus {
      outline: none;
      border: none;
      border-bottom: solid 3px;
      box-shadow: none;
    }
  }
  textarea.form-control {
    height: 100px;
  }
  input[type=submit] {
    margin: 30px 0;
    border: solid 2px #ccc;
    border-radius: 0;
    padding: 10px 25px;
    float: right;
    transition: all 0.3s;
    @include up-bold;

    &:hover {
      border: solid 2px #222;
      background-color: #fafafa;
    }
  }
}