// vendor
@import "vendor/bootstrap.min";
@import "vendor/font-awesome.min";

@import "mixins";
@import "type";
@import "animate";
@import "partials/bootstrap-components";

// includes
@import "partials/shared";
@import "partials/menu";
@import "partials/sidebar";

// pages
@import "partials/main-content";
@import "partials/single";
@import "partials/favorites";
@import "partials/category";
@import "partials/contact";
@import "partials/author";
@import "partials/alt-home";
@import "partials/page-404";
@import "partials/home";

@import "syntax-highlighting";
@import "nojs";

$brand-color: #0aa0f4;
$brand-secondary-color: #0000cd;

.left-container {
  margin-left: 15px;
  padding-left: 0;

  @include breakpoint(only-sm) {
    max-width: 100%;
    width: 100%;
  }
}
.no-gutter {
  padding-left: 0;
  padding-right: 0;
}
.no-gutter-left {
  padding-left: 0;
}
.no-gutter-right {
  padding-right: 0;
}
html,
body {
  font-size: 62.5%;

  @include breakpoint(md) {
    height: 100%;
  }
}
.hide {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}
img {
  max-width: 100%;
}
main {
  display: block;
}

a {
  color: $brand-color;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: $brand-secondary-color;
    border-bottom: solid 1px $brand-secondary-color;
  }
}

small {
  font-size: 60%;
}

table {
  table-layout: fixed;
  border-collapse: collapse;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(38, 42, 56);
  margin: 0 auto;

  th {
    background-color: rgb(224, 227, 232);
  }

  th,
  td {
    padding: 2px 10px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(38, 42, 56);
  }
}

.video {
  aspect-ratio: 16 / 9;
  width: 100%;
  min-height: 200px;
}


pre.highlight {
  background-color: #fbfbfb;
}
