.highlight .hll {
  background-color: #ffffcc;
}
.highlight,
figure.highlight pre {
  background: #ffffff;
}
.highlight .c {
  color: #bbbbbb;
  font-style: italic;
} /* Comment */
.highlight .k {
  color: #d73a49;
} /* Keyword */
.highlight .ch {
  color: #bbbbbb;
  font-style: italic;
} /* Comment.Hashbang */
.highlight .cm {
  color: #bbbbbb;
  font-style: italic;
} /* Comment.Multiline */
.highlight .cp {
  color: #bbbbbb;
  font-style: italic;
} /* Comment.Preproc */
.highlight .cpf {
  color: #bbbbbb;
  font-style: italic;
} /* Comment.PreprocFile */
.highlight .c1 {
  color: #bbbbbb;
  font-style: italic;
} /* Comment.Single */
.highlight .cs {
  color: #bbbbbb;
  font-style: italic;
} /* Comment.Special */
.highlight .kc {
  color: #0000ff;
} /* Keyword.Constant */
.highlight .kd {
  color: #d73a49;
} /* Keyword.Declaration */
.highlight .kn {
  color: #0000ff;
} /* Keyword.Namespace */
.highlight .kp {
  color: #0000ff;
} /* Keyword.Pseudo */
.highlight .kr {
  color: #0000ff;
} /* Keyword.Reserved */
.highlight .kt {
  color: #0000ff;
} /* Keyword.Type */
.highlight .s {
  color: #009c00;
} /* Literal.String */
.highlight .nc {
  color: #007575;
} /* Name.Class */
.highlight .nd {
  color: #cc00a3;
} /* Name.Decorator */
.highlight .nf {
  color: #c34e00;
} /* Name.Function */
.highlight .sa {
  color: #009c00;
} /* Literal.String.Affix */
.highlight .sb {
  color: #009c00;
} /* Literal.String.Backtick */
.highlight .sc {
  color: #009c00;
} /* Literal.String.Char */
.highlight .dl {
  color: #009c00;
} /* Literal.String.Delimiter */
.highlight .sd {
  color: #009c00;
} /* Literal.String.Doc */
.highlight .s2 {
  color: #032f62;
} /* Literal.String.Double */
.highlight .se {
  color: #032f62;
} /* Literal.String.Escape */
.highlight .sh {
  color: #032f62;
} /* Literal.String.Heredoc */
.highlight .si {
  color: #032f62;
} /* Literal.String.Interpol */
.highlight .sx {
  color: #032f62;
} /* Literal.String.Other */
.highlight .sr {
  color: #6f42c1;
} /* Literal.String.Regex */
.highlight .s1 {
  color: #032f62;
} /* Literal.String.Single */
.highlight .ss {
  color: #032f62;
} /* Literal.String.Symbol */
.highlight .fm {
  color: #c34e00;
} /* Name.Function.Magic */

.highlight .mi {
  color: #005cc5;
}

.highlight .nx {
  color: #6f42c1;
}

.highlight .o,
.highlight .p {
  color: #005cc5;
}

.highlight .na {
  color: #0550ae;
}

.highlight .nc,
.highlight .nt {
  color: #953800;
}

.highlight .gi {
  background-color: #f1fff4;
  color: #31873e;
}

.highlight .gd {
  background-color: #feedf0;
  color: #bf002a;
}

.highlight .gu {
  color: #8250df;
}

pre code {
  white-space: inherit;
}
