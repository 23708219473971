.meta {
  color: $light-grey;
  @include font-size(14);

  @include breakpoint(md) {
    @include font-size(16);
  }
  a {
    color: $light-grey;

    &:hover,
    &:active {
      text-decoration: none;
      color: $text-color;
      border-bottom: 1px solid $light-grey;
    }
  }
  i {
    font-style: normal;
  }
}
.btn-default {
  padding: 10px 15px;
  background: #fafafa;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  text-shadow: 0 0 0;
  border-radius: 4px;
}
.link-spacer {
  margin: 0 2px 4px 2px;
  display: inline-block;
  height: 2px;
  width: 2px;
  background-color: $light-grey;
  border-radius: 100%;
}
.user-icon {
  width: 50px;
  height: 50px;
  padding: 0;
  float: right;
  border-radius: 50%;
}