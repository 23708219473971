.author-bio {
  @include clearfix;
  img {
    border-radius: 50%;
    width: 150px;
    float: left;

    @include breakpoint(md) {
      float: right;
      width: 220px;
    }
  }
  .author-bio__info {
    float: left;

    h1 {
      margin: 30px 0 20px;
    }
  }
}
.view-all-by-author {
  text-align: right;
  margin: 20px 0;
  a {
    @include font-size(13);
    @include up-bold;
  }

  i {
    margin-left: 5px;
    @include font-size(9);
    transform: translateY(-1px);
  }
}